<!-- 
	This is the sign in page, it uses the dashboard layout in: 
	"./layouts/Default.vue" .
 -->

 <template>
	<div class="sign-in bg-gray-3">
		
		<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" style="margin-top: 80px;">

			<a-col :span="24" :md="10" :lg="10" :xl="{span: 8, offset: 0}" class="col-form pt-5 mt-5">
				
				<a-card :bordered="false" class="card-info px-3 mx-0 pt-20">
					<a-row :gutter="[24,24]">
						
						<a-col :span="24" :md="24" :lg="24" class="px-25">
							<a-row class="text-center">
								<a-col :lg="24">
									<!-- <img src="../../public/images/icon.png" alt="" width="80px" height="80px" style="border-radius: 20px;">  -->
								</a-col>
							</a-row>
							<h3 class="mb-0 mt-3 text-center">Verify Account!</h3>
							<p class="text-muted text-center mt-1 pt-0 mb-4">A token has been sent to your phone number you submitted in previous step.</p>

							<a-alert
								class="mb-3"
								v-if="error.show"
								:message="error.message"
								type="error"
								closable
								:after-close="handleClose"
								show-icon 
							/>

							<a-alert
                                class="mx-25"
								v-if="success.show"
								:message="success.message"
								type="success"
								closable
								:after-close="handleClose"
								show-icon 
							/>

							<a-form
								id="components-form-demo-normal-login"
								:form="form"
								class="login-form"
								@submit="handleSubmit"
								:hideRequiredMark="true">

								<a-form-item class="mb-3" label="Token" :colon="false">
									<a-input
									v-decorator="[
									'token',
									{ rules: [{ required: true, message: 'Please input token!' }] },
									]" type="text" placeholder="Token" />
								</a-form-item>

								<a-form-item class="mt-4">
									<a-button :loading="isLoading" type="primary" block html-type="submit" class="login-form-button mt-10 text-white" >
										VERIRY
									</a-button>
								</a-form-item>


								<a-row :gutters="24" class="pt-0">
									<a-col :span="24" class="text-center pb-5">
										Didn't receive the token?&nbsp; <a-button type="text" :loading="loadingToken"  class="ml-2 pl-0 text-primary" style="font-size: 14px; text-decoration: none !important; border: none;" @click="resendToken">
											<strong>Resend</strong>
										</a-button>
									</a-col>
								</a-row>

								<!-- <a-row :gutters="24" class="pt-10 pb-10">
									<a-col :span="24" class="text-center pb-5">
										Already have an account? <a class="" style="text-decoration: none !important; color: #632163;" :href="`/sign-up`">
											<strong>Sign in</strong>
										</a>
									</a-col>
								</a-row> -->

							</a-form>
						</a-col>
					</a-row>
				</a-card>
			</a-col>


		</a-row>
		
	</div>
</template>

<script>
	
	export default ({
		

		data() {
			return {
				// Binded model property for "Sign In Form" switch button for "Remember Me" .
				error: {
					message: null,
					show: false,
				},

				success: {
					message: null,
					show: false,
				},

				countryCode: '255',

				isLoading: false,

				loadingToken: false,

				notify: {
					text: null,
					type: null,
				},
			}
		},
		beforeCreate() {
			// Creates the form and adds to it component's "form" property.
			this.form = this.$form.createForm(this, { name: 'normal_login' });
		},
		created() {
		},
		methods: {

			onSelectCountry({dialCode}) {
				this.countryCode = dialCode;
			},

			handleClose() {
				this.error.show = false;
			},

			async resendToken() {

				this.loadingToken = true;
				
				let userDetails = await JSON.parse(localStorage.getItem("zegeUser"));

				let url = `${this.$BACKEND_URL}/auth/resend-token`;
				
				this.$AXIOS.post(url, { username: userDetails.phone }).then(async(response) => {
					
					if (response.status >= 200 && response.status < 210) {

						this.success.message = `Token has been sent to ${userDetails.phone} successfully`
						this.success.show = true;

						this.loadingToken = false;

						// this.$notify(this.notify)
					
					}

					// this.isLoading = false
				}).catch((error) => {
					// eslint-disable-next-line no-console
					console.error(error)
					this.loadingToken = false;
					
					this.error.message = error.response != null  && error.response.data != null ? error.response.data.message : "Connection error";
					this.error.show = true
				});
			},

			// Handles input validation after submission.
			handleSubmit(e) {
				e.preventDefault();
				this.form.validateFields(async(err, values) => {
					if ( !err ) {
						this.error.show = false;
						this.error.message = null;
						this.isLoading = true;

						const token = values.token;

						let userDetails = await JSON.parse(localStorage.getItem("zegeUser"));

						let url = `${this.$BACKEND_URL}/auth/verify-account`;
						
						this.$AXIOS.post(url, { token, phone: userDetails.phone }).then(async(response) => {
							if (response.status >= 200 && response.status < 210) {

								this.notify.text = "Account verification was successfully"
								this.notify.type = "success"

								this.$notify(this.notify)
								
								this.isLoading = false;
								

								this.$router.push(`/`);
							}

							this.isLoading = false
						}).catch((error) => {
							this.isLoading = false;
							
							this.error.message = error.response != null  && error.response.data != null ? error.response.data.message : "Connection error";
							this.error.show = true
						});
					}
				});
			},

		},
		
	})

</script>

<style lang="scss" scoped>
	body {
		background-color: #ffffff;
	}
	#signinBtn, #signinBtn:hover {
		background-color: #632163 !important;
		color: white !important;
	}
</style>
<!-- 
	This is the sign in page, it uses the dashboard layout in: 
	"./layouts/Default.vue" .
 -->
